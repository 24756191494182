import React, { useState } from "react";

interface Props {
  children: React.ReactElement;
}

export interface EidContextValues {
  documentId: string | null;
  setDocumentId: (documentId: string) => void;
  document: string | null;
  setDocument: (document: string) => void;
  signedDocument: string | null;
  setSignedDocument: (document: string) => void;
  videoId: string | null;
  setVideoId: (videoId: string) => void;
  verificationId: string | null;
  setVerificationId: (verificationId: string) => void;
  phone: string | null;
  setPhone: (phone: string | null) => void;
  progress: number;
  setProgress: (progress: number) => void;
  email: string | null;
  setEmail: (email: string | null) => void;
  redirectUri: string | null;
  setRedirectUri: (document: string | null) => void;
  leadId: string | null;
  setLeadId: (leadId: string | null) => void;

}

const EidContext = React.createContext<EidContextValues>({
  documentId: null,
  setDocumentId: (documentId: string) => {},
  document: null,
  setDocument: (document: string) => {},
  signedDocument: null,
  setSignedDocument: (document: string) => {},
  videoId: null,
  setVideoId: (videoId: string) => {},
  verificationId: null,
  setVerificationId: (verificationId: string) => {},
  phone: null,
  setPhone: (phone: string | null) => {},
  progress: 10,
  setProgress: (progress: number) => {},
  email: null,
  setEmail: (email: string | null) => {},
  redirectUri: null,
  setRedirectUri: (document: string | null) => {},
  leadId: null,
  setLeadId: (leadId: string | null) => {}
});

export default function WithEidContext({
  children,
}: Props): React.ReactElement {
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [document, setDocument] = useState<string | null>(null);
  const [signedDocument, setSignedDocument] = useState<string | null>(null);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [redirectUri, setRedirectUri] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(10);
  const [leadId, setLeadId] = useState<string | null>(null);

  return (
    <EidContext.Provider
      value={{
        documentId: documentId,
        setDocumentId: setDocumentId,
        document: document,
        setDocument: setDocument,
        signedDocument: signedDocument,
        setSignedDocument: setSignedDocument,
        videoId: videoId,
        setVideoId: setVideoId,
        verificationId: verificationId,
        setVerificationId: setVerificationId,
        phone: phone,
        setPhone: setPhone,
        progress: progress,
        setProgress: setProgress,
        email: email,
        setEmail: setEmail,
        redirectUri: redirectUri,
        setRedirectUri: setRedirectUri,
        leadId: leadId,
        setLeadId: setLeadId
      }}
    >
      {children}
    </EidContext.Provider>
  );
}

export { EidContext };
