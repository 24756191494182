import React from "react";
import logo from "./logo.svg";
import "./App.css";
import WithEidContext, { EidContext } from "./EidContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Init from "./Init";
import Error from "./Error";
import VideoId from "./VideoId";
import Verification from "./Verification";
import Signature from "./Signature";
import "./i18n";
import Progress from "./Progress";
import Success from "./Success";

/* 

1. Init the flow and 
2. Video ID.       MVP

(Wartezeit)

3. Verifikation.
4. Signatur.
5. Download Dokument.

 */
function TgfEidApp() {
  return (
    <WithEidContext>
      <div className="App">
        <div className="container mx-auto p-4">
          <div className="container mx-auto">
            <EidContext.Consumer>
              {({ progress }) => <Progress progress={progress}></Progress>}
            </EidContext.Consumer>
          </div>
          <div className="container mx-auto p-4" />
          <div className="container mx-auto">
            <Router>
              <Routes>
                <Route path="/init" element={<Init />}></Route>
                <Route path="/videoid" element={<VideoId />}></Route>
                <Route path="/verification" element={<Verification />}></Route>
                <Route path="/sign" element={<Signature />}></Route>
                <Route path="/success" element={<Success />}></Route>
                <Route path="/error" element={<Error />}></Route>
              </Routes>
            </Router>
          </div>
        </div>
      </div>
    </WithEidContext>
  );
}

export default TgfEidApp;
