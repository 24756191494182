import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EidContext } from "./EidContext";
import { assembleRedirectUri } from "./redirect";
import RedirectAfterSeconds from "./RedirectAfterSeconds";
import StatusCode from "./statusCodes";

const Success = () => {
  const ctx = useContext(EidContext);
  ctx.setProgress(100);

  const [t] = useTranslation();

  ctx.redirectUri &&
    window.location.assign(
      assembleRedirectUri(ctx.redirectUri, ctx.leadId || "", StatusCode.C001)
    );

  return (
    <div>
      <p>{t("you signed successfully signed")}</p>
    </div>
  );
};

export default Success;
