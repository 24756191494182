import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "./axios";
import TgfButton from "./Button";
import { EidContext, EidContextValues } from "./EidContext";
import SignatureTypeSelection, {
  SignatureType,
} from "./SignatureTypeSelection";

enum State {
  SELECT_METHOD = "SELECT_METHOD",
  WAIT_FOR_CODE = "WAIT_FOR_CODE",
}

const coordsByLanguage = (lang: string) => {
  switch (lang) {
    case "de":
      return { left: 330, top: 195 };
    default:
      return { left: 30, top: 30 };
  }
};

const requestEndpointByMethod = (type: SignatureType | null) => {
  switch (type) {
    case SignatureType.EMAIL:
      return "/signatures/request/email";
    case SignatureType.PHONE:
      return "/signatures/request/sms";
    default:
      return "/signatures/request/sms";
  }
};

const signEndpointByMethod = (type: SignatureType | null) => {
  switch (type) {
    case SignatureType.EMAIL:
      return "/signatures/sign/email";
    case SignatureType.PHONE:
      return "/signatures/sign/sms";
    default:
      return "/signatures/sign/sms";
  }
};

const createIdentity = async (ctx: EidContextValues) => {
  const identityRes = await axios.post("/identities/video", {
    verificationId: ctx.verificationId,
    phone: ctx.phone,
  });
  return identityRes.data.id;
};
interface SignDocumentProps {
  onSignatureSuccess: () => void;
}

const SignDocument = ({ onSignatureSuccess }: SignDocumentProps) => {
  const ctx = useContext(EidContext);

  const [state, setState] = useState(State.SELECT_METHOD);
  const [code, setCode] = useState<string>("");
  const [method, setMethod] = useState<SignatureType | null>(null);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [isSubmiting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [identityId, setIdentityId] = useState<string | null>(null);
  const [t, i18n] = useTranslation();
  /*   useEffect(() => {
    createIdentity(ctx).then((res) => {
      setIdentityId(res);
    });
  }, []); */

  useEffect(() => {
    setCode("");
  }, [method]);

  useEffect(() => {
    setError(null);
  }, [code]);

  const getOrCreateIdentity = async (ctx) => {
    if (identityId === null) {
      const localIdentityId = await createIdentity(ctx);
      setIdentityId(localIdentityId);
      return localIdentityId;
    } else {
      return identityId;
    }
  };

  const onSelection = async (type: SignatureType) => {
    setIsSubmitting(true);
    setMethod(type);
    const signatureRequest: any = {
      documentsId: [ctx.documentId],
      identityId: await getOrCreateIdentity(ctx),
      challengeCode: {
        charset: "alphanumeric",
        length: 6,
      },
    };
    switch (type) {
      case SignatureType.PHONE:
        signatureRequest.message = {
          phone: ctx.phone,
          text: t("sign request message"),
          from: "TGF",
        };
        break;
      case SignatureType.EMAIL:
        signatureRequest.attachDocuments = false;
        signatureRequest.message = {
          fromName: "The Generation Forest",
          from: "thegenerationforest@electronicid.eu",
          to: ctx.email,
          subject: t("sign request subject"),
          body: t("sign request message"),
        };
        break;
    }

    const requestSignatureRes = await axios.post(
      requestEndpointByMethod(type),
      signatureRequest
    );
    setRequestId(requestSignatureRes.data.requestId);
    setState(State.WAIT_FOR_CODE);
    setIsSubmitting(false);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    const coords = coordsByLanguage(i18n.resolvedLanguage)
    const signReq = {
      requestId: requestId,
      challengeCode: code,
      stamps: [
        {
          documentId: ctx.documentId,
          location: {
            page: 2,
            left: coords.left,
            top: coords.top,
            width: 220,
            height: 60,
          },
          textSettings: {
            dateFormat: "dd/MM/yyyy HH:mm",
            timeZone: "GMT+1",
            language: i18n.resolvedLanguage,
          },
        },
      ],
    };
    try {
      await axios.post(signEndpointByMethod(method), signReq);

      setIsSubmitting(false);
      onSignatureSuccess();
    } catch (error) {
      setError(t("wrong code"));
      setIsSubmitting(false);
    }
  };

  const translateMethod = (method: SignatureType | null) => {
    switch (method) {
      case SignatureType.EMAIL:
        return t("email_name");
      case SignatureType.PHONE:
        return t("phone_name");
      default:
        return "";
    }
  };

  return (
    <div>
      {state === State.SELECT_METHOD && (
        <SignatureTypeSelection
          onSelection={onSelection}
          loading={isSubmiting}
        />
      )}
      {state === State.WAIT_FOR_CODE && (
        <>
          <form onSubmit={onSubmit}>
            <>{t("sign with code", { method: translateMethod(method) })}</>

            <div className="container flex flex-row justify-center gap-10 m-4">
              <div>
                <input
                  onSubmit={onSubmit}
                  type="text"
                  value={code}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(event) => setCode(event.target.value)}
                />
                {error && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    {error}
                  </p>
                )}
              </div>

              <TgfButton
                label={t("sign")}
                onClick={() => onSubmit()}
                loading={isSubmiting}
              />
            </div>
          </form>
          <div className="m-4">
            <a
              className="underline cursor-pointer"
              onClick={() => setState(State.SELECT_METHOD)}
            >
              {t("use different method")}
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default SignDocument;
