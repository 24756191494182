import axios from "./axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { EidContext } from "./EidContext";
import PollVerificationId from "./PollVerificationId";
import { useTranslation } from "react-i18next";

enum VerificationStatus {
  Accepted = "Accepted",
  Rejected = 'Rejected'
}

const Verification = () => {
  const ctx = useContext(EidContext);
  const navigate = useNavigate();

  const [t] = useTranslation();

  ctx.setProgress(40);

  useEffect(() => {
    if (ctx.videoId === null) {
      navigate("/error");
    }
    if (ctx.verificationId) {
      return;
    }

    axios
      .post("/verification_requests", {
        videoId: ctx.videoId,
      })
      .then((res) => {
        ctx.setVerificationId(res.data.id);
      })
      .catch((error) => {
        navigate("/error");
      });
  }, []);

  const onVerificationDone = (status: VerificationStatus) => {
    if (status === VerificationStatus.Accepted) {
      navigate("/sign");
    } else {
      navigate("/error");
    }    
  };

  return (
    <>
      {ctx.verificationId && (
        <>
          <PollVerificationId onVerificationDone={onVerificationDone} />
          <div className="container mx-auto m-4 container">
            <p>{t("your data")}</p>
          </div>
          <div className="flex flex-row justify-center m-4">
              <YouTube
                videoId="Lb1duDUZWxI"
                opts={{ playerVars: { autoplay: 1 } }}
              />
          </div>
        </>
      )}
    </>
  );
};

export default Verification;
export { VerificationStatus }
