import axios from "./axios";
import React, { useContext, useEffect, useState } from "react";
import { EidContext } from "./EidContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./VideoID.css";
import { useTranslation } from "react-i18next";
import LoadingScreen from "./LoadingScreen";

declare global {
  interface Window {
    EID: any;
  }
}

const srcByEnv = () => {
  switch (window.location.hostname) {
    case "eid.thegenerationforest.com":
      return "https://etrust-live.electronicid.eu/js/videoid-3.x/videoid.js";
    default:
      return "https://etrust-sandbox.electronicid.eu/js/videoid-3.x/videoid.js";
  }
};

const VideoId = () => {
  const ctx = useContext(EidContext);
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = srcByEnv();
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      setReady(true)
      const request = {
        phone: ctx.phone,
      };
      axios.post("/videoid.request", request).then((res) => {
        var videoId = window.EID.videoId("#video", {
          lang: i18n.resolvedLanguage,
        });
        videoId.start({
          authorization: res.data.authorization,
          allowedIdTypes: [
            1, 2, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 20, 21, 24, 25,
            26, 27, 28, 29, 30, 31, 32, 33, 34, 36, 37, 40, 42, 43, 44, 45, 46,
            48, 49, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 64, 65, 67, 68,
            71, 72, 74, 75, 76, 77, 78, 79, 80, 81, 82, 84, 85, 86, 87, 88, 89,
            90, 92, 93, 94, 95, 97, 98, 99, 100, 101, 102, 103, 105, 106, 107,
            109, 111, 113, 114, 116, 119, 120, 121, 123, 124, 126, 128, 129,
            130, 131, 132, 133, 134, 135, 137, 138, 139, 140, 141, 142, 143,
            144, 145, 147, 150, 151, 152, 154, 155, 156, 158, 159, 160, 161,
            163, 166, 169, 170, 172, 173, 174, 175, 176, 177, 178, 179, 180,
            181, 182, 183, 184, 185, 186, 187, 194, 226, 233, 242, 253, 275,
            284, 293, 302, 306, 308, 310, 311
          ],
        });
        videoId.on("completed", function (video: any) {
          ctx.setVideoId(video.id);
          navigate("/verification");
        });
        videoId.on("failed", function (error: any) {
          navigate("/error");
        });
        videoId.on("notification", function (event) {

        });
      });
    };
  }, []);
  return (
    <>
      {ready ? (
        <>
          <div id="video" />
          <div className="container mx-auto" style={{ maxWidth: "640px" }}>
            <p className="text-xs">{t("german_id_card")}</p>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default VideoId;
