import React from "react";

interface ProgressProps {
  progress: number;
}

export default function Progress({ progress }: ProgressProps) {
  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
      <div className="bg-tgf h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
    </div>
  );
}
