import axios from "./axios";
import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EidContext, EidContextValues } from "./EidContext";
import { redirect } from "./redirect";
import LoadingScreen from "./LoadingScreen";
import React from "react";


const PARAM_KEY_DOCUMENT_ID = "eid_document_id";

const validateParams = (params: URLSearchParams): boolean => {
  if (params.get(PARAM_KEY_DOCUMENT_ID) === null) {
    return false;
  }
  if (!(params.get("phone") !== null || params.get("email") !== null)) {
    return false;
  }
  return true;
};

const Init = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const ctx = useContext(EidContext);

  const devInterceptor = () => {
    return "/videoid";
  };

  if (!validateParams(searchParams)) {
    navigate("/error");
  }

  ctx.setPhone(searchParams.get("phone"));
  ctx.setEmail(searchParams.get("email"));
  ctx.setRedirectUri(searchParams.get('website_redirect_uri'))
  ctx.setLeadId(searchParams.get('lead_id'))

  const testStatusCode = searchParams.get('test_status_code')

  testStatusCode && redirect(searchParams.get('website_redirect_uri') || "", searchParams.get('lead_id') || "", testStatusCode)

  useEffect(() => {
    const docId = searchParams.get(PARAM_KEY_DOCUMENT_ID)
    axios
      .get(`/documents/${docId}`)
      .then((res) => {
        ctx.setDocument(res.data.document);
        ctx.setDocumentId(docId || "")
        navigate(devInterceptor());
      })
      .catch((error) => navigate("/error"));
  }, []);

  return <LoadingScreen />;
};

export default Init;
