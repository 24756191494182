import axios from "./axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { EidContext } from "./EidContext";
import PollVerificationId from "./PollVerificationId";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

interface ShowPdfProps {
  pdfBase64: string;
}

const ShowPdf = ({ pdfBase64 }: ShowPdfProps) => {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    
      <Document
        file={`data:application/pdf;base64,${pdfBase64}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {[...Array(numPages)].map((x, i) => (
          <Page key={i} scale={2} pageIndex={i} />
        ))}
      </Document>
    
  );
};

export default ShowPdf;
