import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EidContext } from "./EidContext";
import ShowPdf from "./ShowPdf";
import SignDocument from "./SignDocument";

const Signature = () => {
  const ctx = useContext(EidContext);
  const navigate = useNavigate();

  const [t] = useTranslation()

  ctx.setProgress(60)

  return (
    <div>
      <div className="m-4">
        {t("last step")}
      </div>
      <SignDocument onSignatureSuccess={()=> navigate("/success")}/>
      { ctx.document && 
        <ShowPdf pdfBase64={ctx.document} />
      }      
    </div>
  );
};

export default Signature;
