import React, { useContext } from "react";
import { EidContext } from "./EidContext";
import { redirect } from "./redirect";
import StatusCode from "./statusCodes";

const Error = () => {

  const ctx = useContext(EidContext);

  redirect(ctx.redirectUri || "", ctx.leadId || "", StatusCode.C002)

  return (
    <>
      <div>Error occured</div>
    </>
  );
};

export default Error;
