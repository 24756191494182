import axios from "./axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { EidContext } from "./EidContext";
import useInterval from "./intervalHook";
import { VerificationStatus } from "./Verification";

type PollVerificationIdProps = {
  onVerificationDone: (status: VerificationStatus) => void;
};

const PollVerificationId = ({
  onVerificationDone,
}: PollVerificationIdProps) => {
  const navigate = useNavigate();
  const ctx = useContext(EidContext);

  useInterval(() => {
    axios
      .get(`/verification_requests/${ctx.verificationId}`)
      .then((res) => {
        if (res.data.status === "Verified") {
          axios.get(`/verifications/${ctx.verificationId}`).then(res => {
            onVerificationDone(res.data.status);
          })
          
        }
      })
      .catch((error) => navigate("/error"));
  }, 5000);

  return <></>;
};

export default PollVerificationId;
