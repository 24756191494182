const redirect = (to: string, leadId: string, statusCode: string) => {
  const url = new URL(to);
  url.searchParams.append("status_code", statusCode);
  url.searchParams.append("lead_id", leadId);
  window.location.assign(assembleRedirectUri(to, leadId, statusCode));
};

const assembleRedirectUri = (to: string, leadId: string, statusCode: string) => {
    const url = new URL(to);
    url.searchParams.append("status_code", statusCode);
    url.searchParams.append("lead_id", leadId);
    return url.toString()
}

export { redirect, assembleRedirectUri };
