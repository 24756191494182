import axios from "./axios";
import React, { useContext, useEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { EidContext } from "./EidContext";
import PollVerificationId from "./PollVerificationId";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import TgfButton from "./Button";

enum SignatureType {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

interface SignatureTypeSelectionProps {
  onSelection: (type: SignatureType) => void;
  loading: boolean;
}

const SignatureTypeSelection = ({
  onSelection,
  loading,
}: SignatureTypeSelectionProps) => {
  const ctx = useContext(EidContext);

  const [t] = useTranslation();

  const [selected, setSelected] = useState<SignatureType>(SignatureType.PHONE);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <>
      <div>
        <p>{t("how would you like to sign")}</p>
      </div>
      <div className="container flex flex-row justify-center gap-10 m-4">
        <div className="relative w-full lg:max-w-sm">
          <select
            disabled={loading}
            className={
              loading
                ? "w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-bg-tgf"
                : "w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-bg-tgf"
            }
            onChange={handleChange}
          >
            {ctx.phone && (
              <option value={SignatureType.PHONE}>
                {t("sms", { phone: ctx.phone })}
              </option>
            )}
            {ctx.email && (
              <option value={SignatureType.EMAIL}>
                {t("email", { email: ctx.email })}
              </option>
            )}
          </select>
        </div>
        <TgfButton label={t("continue")} onClick={() => onSelection(selected)} loading={loading} />
      </div>
    </>
  );
};

export default SignatureTypeSelection;

export { SignatureType };
